import {
    Table as ChakraTable,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react'
import { Text as TextDS } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { theme } from 'common/components/design-system/theme'
import { Text } from 'common/components/molecules/Text'
import React from 'react'
import { parseFinancialValue } from '../utils'

export interface TableProps {
    benchmarkData: any
    performanceData: any
    filter: any
    showErrorState: boolean
    errorCode: number
    errorMessage: string
    showTable: boolean
}

export const Table: React.FC<TableProps> = ({
    benchmarkData,
    performanceData,
    filter,
    showErrorState,
    errorCode,
    errorMessage,
    showTable,
}) => {
    const { palette } = theme
    const useStyles = makeStyles({
        tableTextStrong: {
            color: 'rgba(58, 49, 96, 1)',
            fontWeight: 600,
            '& div': {
                fontWeight: 400,
            },
        },
        stripedCell: {
            backgroundColor: '#F7F8FB',
        },
        tableBase: {
            '& tr td': {
                border: '1px solid #EBEDF7',
            },
        },
    })
    const classes = useStyles()
    if (showErrorState && errorCode === 2)
        return (
            <Text
                variant='h3'
                style={{ textAlign: 'center' }}
                label={errorMessage}
            />
        )

    if (!showTable) return null
    return (
        <>
            <>
                <Text
                    variant={'h4'}
                    label={'Fund Objectives'}
                    style={{
                        marginTop: '2em',
                        marginBottom: '0.75em',
                    }}
                />
                <TextDS>{benchmarkData?.FundObjectivesText}</TextDS>
            </>

            <>
                <Text
                    variant={'h4'}
                    label={'Performance Scenario Results'}
                    style={{
                        marginTop: '2em',
                        marginBottom: '0.75em',
                    }}
                />
                <Text
                    label={
                        'This performance scenario table is from the Key Information Document (KID) for this product and fund.'
                    }
                />

                <Text
                    variant={'h6'}
                    // Investment amount varies based on premium type
                    label={
                        filter.PremiumTypeDesc === 'Regular Premium'
                            ? `Investment amount: €1,000`
                            : `Investment amount: €10,000`
                    }
                    style={{ marginTop: '0.75em' }}
                />
                <Box marginTop={8} marginBottom={8}>
                    <TableContainer>
                        <ChakraTable
                            className={classes.tableBase}
                            id='performance_scenario_table'
                        >
                            <Thead backgroundColor={palette.BRAND_200}>
                                <Tr height='70px'>
                                    <Th>Survival Scenarios</Th>
                                    <Th></Th>
                                    <Th>If you cash in after 1 year</Th>
                                    <Th>
                                        If you cash in after{' '}
                                        {performanceData.RecommHoldingPeriod}{' '}
                                        years
                                        <div>
                                            <span>
                                                (Recommended holding period)
                                            </span>
                                        </div>
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr height='70px'>
                                    <Td className={classes.tableTextStrong}>
                                        Minimum
                                    </Td>
                                    {performanceData.SurvivalMinimumAmount1Year !==
                                        null &&
                                    performanceData.SurvivalMinimumAmountRecommendedPeriod !==
                                        null ? (
                                        <>
                                            <Td
                                                className={
                                                    classes.tableTextStrong
                                                }
                                            >
                                                What you might get back after
                                                costs
                                                <div>
                                                    Average return each year
                                                </div>
                                            </Td>
                                            <Td
                                                className={
                                                    classes.tableTextStrong
                                                }
                                            >
                                                {parseFinancialValue(
                                                    performanceData.SurvivalMinimumAmount1Year
                                                )}
                                                <div>
                                                    {
                                                        performanceData.SurvivalMinimumAverageReturn1Year
                                                    }{' '}
                                                    %
                                                </div>
                                            </Td>
                                            <Td
                                                className={
                                                    classes.tableTextStrong
                                                }
                                            >
                                                {parseFinancialValue(
                                                    performanceData.SurvivalMinimumAmountRecommendedPeriod
                                                )}
                                                <div>
                                                    {
                                                        performanceData.SurvivalMinimumAverageReturnRecommendedPeriod
                                                    }{' '}
                                                    %
                                                </div>
                                            </Td>
                                        </>
                                    ) : (
                                        <Td
                                            sx={{
                                                textAlign: 'center',
                                            }}
                                            className={classes.tableTextStrong}
                                            colSpan={3}
                                        >
                                            There is no minimum guaranteed
                                            return. You could
                                            <br />
                                            lose some or all of your investment.
                                        </Td>
                                    )}
                                </Tr>
                                <Tr
                                    height='70px'
                                    className={classes.stripedCell}
                                >
                                    <Td className={classes.tableTextStrong}>
                                        Stress Scenario
                                    </Td>
                                    <Td className={classes.tableTextStrong}>
                                        What you might get back after costs
                                        <div>Average return each year</div>
                                    </Td>
                                    <Td className={classes.tableTextStrong}>
                                        {parseFinancialValue(
                                            performanceData.StressAmount1Year
                                        )}
                                        <div>
                                            {
                                                performanceData.StressAverageReturn1Year
                                            }{' '}
                                            %
                                        </div>
                                    </Td>
                                    <Td className={classes.tableTextStrong}>
                                        {parseFinancialValue(
                                            performanceData.StressAmountRecommendedPeriod
                                        )}
                                        <div>
                                            {
                                                performanceData.StressAverageReturnRecommendedPeriod
                                            }{' '}
                                            %
                                        </div>
                                    </Td>
                                </Tr>
                                <Tr height='70px'>
                                    <Td className={classes.tableTextStrong}>
                                        Unfavourable Scenario
                                    </Td>
                                    <Td className={classes.tableTextStrong}>
                                        What you might get back after costs
                                        <div>Average return each year</div>
                                        <div>
                                            This type of scenario occurred for
                                            an investment
                                            <div>between:</div>
                                        </div>
                                    </Td>
                                    <Td className={classes.tableTextStrong}>
                                        {parseFinancialValue(
                                            performanceData.UnfavourableAmount1Year
                                        )}
                                        <div>
                                            {
                                                performanceData.UnfavourableAverageReturn1Year
                                            }{' '}
                                            %
                                        </div>
                                        <div>
                                            {
                                                performanceData.UnfavourableStartDate1Year
                                            }
                                            {' to '}
                                            {
                                                performanceData.UnfavourableEndDate1Year
                                            }
                                        </div>
                                    </Td>
                                    <Td className={classes.tableTextStrong}>
                                        {parseFinancialValue(
                                            performanceData.UnfavourableAmountRecommendedPeriod
                                        )}
                                        <div>
                                            {
                                                performanceData.UnfavourableAverageReturnRecommendedPeriod
                                            }{' '}
                                            %
                                        </div>
                                        <div>
                                            {
                                                performanceData.UnfavourableStartDateRecommendedPeriod
                                            }
                                            {' to '}
                                            {
                                                performanceData.UnfavourableEndDateRecommendedPeriod
                                            }
                                        </div>
                                    </Td>
                                </Tr>
                                <Tr
                                    height='70px'
                                    className={classes.stripedCell}
                                >
                                    <Td className={classes.tableTextStrong}>
                                        Moderate Scenario
                                    </Td>
                                    <Td className={classes.tableTextStrong}>
                                        What you might get back after costs
                                        <div>Average return each year</div>
                                        <div>
                                            This type of scenario occurred for
                                            an investment
                                            <div>between:</div>
                                        </div>
                                    </Td>
                                    <Td className={classes.tableTextStrong}>
                                        {parseFinancialValue(
                                            performanceData.ModerateAmount1Year
                                        )}
                                        <div>
                                            {
                                                performanceData.ModerateAverageReturn1Year
                                            }{' '}
                                            %
                                        </div>
                                        <div>
                                            {
                                                performanceData.ModerateStartDate1Year
                                            }
                                            {' to '}
                                            {
                                                performanceData.ModerateEndDate1Year
                                            }
                                        </div>
                                    </Td>
                                    <Td className={classes.tableTextStrong}>
                                        {parseFinancialValue(
                                            performanceData.ModerateAmountRecommendedPeriod
                                        )}
                                        <div>
                                            {
                                                performanceData.ModerateAverageReturnRecommendedPeriod
                                            }{' '}
                                            %
                                        </div>
                                        <div>
                                            {
                                                performanceData.ModerateStartDateRecommendedPeriod
                                            }
                                            {' to '}
                                            {
                                                performanceData.ModerateEndDateRecommendedPeriod
                                            }
                                        </div>
                                    </Td>
                                </Tr>
                                <Tr height='70px'>
                                    <Td className={classes.tableTextStrong}>
                                        Favourable Scenario
                                    </Td>
                                    <Td className={classes.tableTextStrong}>
                                        What you might get back after costs
                                        <div>Average return each year</div>
                                        <div>
                                            This type of scenario occurred for
                                            an investment
                                            <div>between:</div>
                                        </div>
                                    </Td>
                                    <Td className={classes.tableTextStrong}>
                                        {parseFinancialValue(
                                            performanceData.FavourableAmount1Year
                                        )}
                                        <div>
                                            {
                                                performanceData.FavourableAverageReturn1Year
                                            }{' '}
                                            %
                                        </div>
                                        <div>
                                            {
                                                performanceData.FavourableStartDate1Year
                                            }
                                            {' to '}
                                            {
                                                performanceData.FavourableEndDate1Year
                                            }
                                        </div>
                                    </Td>
                                    <Td className={classes.tableTextStrong}>
                                        {parseFinancialValue(
                                            performanceData.FavourableAmountRecommendedPeriod
                                        )}
                                        <div>
                                            {
                                                performanceData.FavourableAverageReturnRecommendedPeriod
                                            }{' '}
                                            %
                                        </div>
                                        <div>
                                            {
                                                performanceData.FavourableStartDateRecommendedPeriod
                                            }
                                            {' to '}
                                            {
                                                performanceData.FavourableEndDateRecommendedPeriod
                                            }
                                        </div>
                                    </Td>
                                </Tr>
                                {performanceData.PremiumType ===
                                    'RegularPremium' && (
                                    <Tr height='70px'>
                                        <Td className={classes.tableTextStrong}>
                                            Accumulated Invested Amount
                                        </Td>
                                        <Td
                                            className={classes.tableTextStrong}
                                        ></Td>
                                        <Td className={classes.tableTextStrong}>
                                            {'€1,000'}
                                        </Td>
                                        <Td className={classes.tableTextStrong}>
                                            {parseFinancialValue(
                                                1000 *
                                                    performanceData.RecommHoldingPeriod
                                            )}
                                        </Td>
                                    </Tr>
                                )}
                            </Tbody>
                            <Thead backgroundColor={palette.BRAND_200}>
                                <Tr height='70px'>
                                    <Th>Death Scenario</Th>
                                    <Th></Th>
                                    <Th></Th>
                                    <Th></Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr height='70px'>
                                    <Td className={classes.tableTextStrong}>
                                        Death Benefit
                                    </Td>
                                    <Td className={classes.tableTextStrong}>
                                        What your beneficiaries might get back
                                        after costs
                                    </Td>
                                    <Td className={classes.tableTextStrong}>
                                        {parseFinancialValue(
                                            performanceData.DeathScenarioAfterCosts1Year
                                        )}
                                    </Td>
                                    <Td className={classes.tableTextStrong}>
                                        {parseFinancialValue(
                                            performanceData.DeathScenarioAfterCostsRecommendedYear
                                        )}
                                    </Td>
                                </Tr>
                            </Tbody>
                        </ChakraTable>
                    </TableContainer>
                </Box>
            </>
            {showErrorState && errorCode === 1 && (
                <Text
                    variant='h3'
                    style={{
                        textAlign: 'center',
                        marginTop: '3em',
                    }}
                    label={errorMessage}
                />
            )}
        </>
    )
}
