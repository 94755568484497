import { Stack } from '@chakra-ui/react'
import { Text as TextDS } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { Alert } from '@irishlife/ilgroupdesignsystem.molecules.alert'
import { Text } from 'common/components/molecules/Text'
import React from 'react'

export interface WarningsProps {
    benchmarkData: any
    showBarChart: boolean
    alerts: { alerts: string[] }
    fundStartYear: string
}

export const Warnings: React.FC<WarningsProps> = ({
    benchmarkData,
    showBarChart,
    alerts,
    fundStartYear,
}) => {
    return (
        <>
            <TextDS
                color='monochrome.700'
                variant='subtitle-md'
                py='28px'
                maxW='632px'
                sx={{ fontWeight: 'bold' }}
            >
                Past performance is not a reliable indicator of future
                performance. Markets could develop very differently in the
                future. It can help you to assess how the fund has been managed
                in the past.
                <br />
                <br />
                Performance is shown after the deduction of ongoing charges. Any
                entry and exit charges are excluded from the calculation.
            </TextDS>
            {benchmarkData?.FundBenchmarkDisclaimerText && (
                <TextDS mb={'20px'} variant={'body-lg'}>
                    {benchmarkData?.FundBenchmarkDisclaimerText}
                </TextDS>
            )}
            {showBarChart && (
                <Text
                    label={`This fund was launched on ${fundStartYear}`}
                    style={{ marginBottom: '1em' }}
                />
            )}

            {alerts && (
                <Stack spacing='20px' pb='20px' maxW='632px'>
                    {alerts.alerts.map((alert) => (
                        <Alert
                            key={alert}
                            status='warning'
                            textProps={{ fontSize: 16 }}
                        >
                            {alert}
                        </Alert>
                    ))}
                </Stack>
            )}
            <TextDS variant={'subtitle-md'} mb={'68px'}>
                While we believe the information set out is reliable, we cannot
                guarantee its accuracy.
            </TextDS>
        </>
    )
}
