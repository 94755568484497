import React from 'react'
import { useDesktop } from 'common/hooks/useDesktop'

export const CustomLabel = (props: any) => {
    const { x, y, width, height, value, hasBenchmarkData } = props
    let signed = false
    const desktop = useDesktop()
    const fontSize = hasBenchmarkData ? '10px' : '13px'
    if (Math.sign(value) === 1) signed = true
    const setSign = !signed ? value?.toFixed(1) * -1 : value?.toFixed(1)

    return (
        <g>
            {value && (
                <polygon
                    points={`
                ${x + width / 2} ${
                        height -
                        (height - y) -
                        (signed ? 9 : -3) -
                        (signed ? 20 : -25)
                    },
                ${x + width / 2 - 6} ${
                        height - (height - y) - 3 - (signed ? 20 : -25)
                    },
                ${x + width / 2 + 6} ${
                        height - (height - y) - 3 - (signed ? 20 : -25)
                    } `}
                    fill='#3A3160'
                />
            )}

            {value && (
                <text
                    x={x + width / 2}
                    y={y}
                    dy={signed ? -5 : 15}
                    fontSize={desktop ? fontSize : '7px'}
                    fontWeight={700}
                    fill='#3A3160'
                    textAnchor='middle'
                    dominantBaseline='top'
                >
                    {desktop && signed ? '+' : ''}
                    {desktop ? value.toFixed(1) : setSign}
                </text>
            )}
        </g>
    )
}
