import { Button } from '@irishlife/ilgroupdesignsystem.atoms.button'
import { Box, Grid, MenuItem, Select } from '@material-ui/core'
import { Text } from 'common/components/molecules/Text'
import React from 'react'
import { getOptions } from '../../FundCentre/KIDS'
import { getKidMonth, getKidYear, Months } from '../utils'

export interface SearchProps {
    documents: any
    filter: any
    setFilter: any
    kidYear: any
    setKidYear: any
    kidMonth: any
    setKidMonth: any
    isDisabled: any
    setIsDisabled: any
    clearFilter: any
    getPerformanceData: any
}

export const Search: React.FC<SearchProps> = ({
    documents,
    filter,
    setFilter,
    kidYear,
    setKidYear,
    kidMonth,
    setKidMonth,
    isDisabled,
    setIsDisabled,
    clearFilter,
    getPerformanceData,
}) => {
    return (
        <>
            <Text
                box={{ mb: 4 }}
                label='Search documents'
                variant='h4'
                component='h1'
            />
            <Box
                bgcolor='#fff'
                borderRadius={10}
                display='flex'
                mb={8}
                py={6}
                px={6}
                width='100%'
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Box
                            display='flex'
                            flexDirection='column'
                            mr={6}
                            width='100%'
                        >
                            <Text
                                box={{ mb: 2 }}
                                label='What type of product is it?'
                                variant='body2'
                            />
                            <Select
                                id='product_type'
                                onChange={(e) =>
                                    setFilter({
                                        PremiumTypeDesc: e.target
                                            .value as string,
                                        PublicProductName: '',
                                        SellerName: '',
                                        PublicFundName: '',
                                    })
                                }
                                placeholder='Select Product Type'
                                value={filter.PremiumTypeDesc}
                                variant='outlined'
                            >
                                {getOptions(
                                    filter,
                                    documents
                                ).PremiumTypeDesc.map((premiumTypeDesc) => (
                                    <MenuItem
                                        key={premiumTypeDesc}
                                        value={premiumTypeDesc}
                                    >
                                        {premiumTypeDesc}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box
                            display='flex'
                            flexDirection='column'
                            mr={6}
                            width='100%'
                        >
                            <Text
                                box={{ mb: 2 }}
                                label='Product Name'
                                variant='body2'
                            />
                            <Select
                                id='product_name'
                                onChange={(e) =>
                                    setFilter({
                                        ...filter,
                                        PublicProductName: e.target
                                            .value as string,
                                        SellerName: '',
                                    })
                                }
                                placeholder='Product Name'
                                value={filter.PublicProductName}
                                variant='outlined'
                            >
                                {getOptions(
                                    filter,
                                    documents
                                ).PublicProductName.map((publicProductName) => (
                                    <MenuItem
                                        key={publicProductName}
                                        value={publicProductName}
                                    >
                                        {publicProductName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box
                            display='flex'
                            flexDirection='column'
                            mr={6}
                            width='100%'
                        >
                            <Text
                                box={{ mb: 2 }}
                                label='Who is your Advisor'
                                variant='body2'
                            />
                            <Select
                                id={'select_advisor'}
                                onChange={(e) =>
                                    setFilter({
                                        ...filter,
                                        SellerName: e.target.value as string,
                                    })
                                }
                                placeholder='Select Advisor'
                                value={filter.SellerName}
                                variant='outlined'
                            >
                                {getOptions(filter, documents).SellerName.map(
                                    (sellerName) => (
                                        <MenuItem
                                            key={sellerName}
                                            value={sellerName}
                                        >
                                            {sellerName}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box
                            display='flex'
                            flexDirection='column'
                            mr={6}
                            width='100%'
                        >
                            <Text
                                box={{ mb: 2 }}
                                label='Fund Name'
                                variant='body2'
                            />
                            <Select
                                id={'fund_name'}
                                onChange={(e) =>
                                    setFilter({
                                        ...filter,
                                        PublicFundName: e.target
                                            .value as string,
                                    })
                                }
                                placeholder='Fund A'
                                value={filter.PublicFundName}
                                variant='outlined'
                            >
                                {getOptions(
                                    filter,
                                    documents
                                ).PublicFundName.map((publicFundName) => (
                                    <MenuItem
                                        key={publicFundName}
                                        value={publicFundName}
                                    >
                                        {publicFundName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box
                            display='flex'
                            flexDirection='column'
                            mr={6}
                            width='100%'
                        >
                            <Text
                                box={{ mb: 2 }}
                                label='Year'
                                variant='body2'
                            />
                            <Select
                                id={'kid_year'}
                                onChange={(e) =>
                                    setKidYear(e.target.value as string)
                                }
                                placeholder='2022'
                                value={kidYear}
                                variant='outlined'
                            >
                                {getKidYear(filter, documents).map(
                                    (element: any) => (
                                        <MenuItem key={element} value={element}>
                                            {element}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Box
                            display='flex'
                            flexDirection='column'
                            mr={6}
                            width='100%'
                        >
                            <Text
                                box={{ mb: 2 }}
                                label='Month'
                                variant='body2'
                            />
                            <Select
                                id={'kid_month'}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setKidMonth(e.target.value as string)
                                }}
                                placeholder='January'
                                value={kidMonth}
                                variant='outlined'
                            >
                                {getKidMonth(filter, kidYear, documents).map(
                                    (element: any) => (
                                        <MenuItem
                                            key={Months.get(element)}
                                            value={Months.get(element)}
                                        >
                                            {Months.get(element)}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </Box>
                    </Grid>
                    <Box flex={1} />
                    <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='flex-end'
                        style={{ marginTop: 30, width: '100%' }}
                    >
                        <Button
                            onClick={getPerformanceData}
                            variant='primary'
                            style={{
                                border: 'none',
                                height: 54,
                                textTransform: 'unset',
                            }}
                            disabled={isDisabled}
                        >
                            Search
                        </Button>

                        <Button
                            onClick={clearFilter}
                            variant='secondary'
                            style={{
                                border: 'none',
                                height: 54,
                                textTransform: 'unset',
                                marginLeft: '1em',
                            }}
                        >
                            Clear
                        </Button>
                    </Box>
                </Grid>
            </Box>
        </>
    )
}
