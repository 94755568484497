import { Stack } from '@chakra-ui/react'
import { Text as TextDS } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { Box, Link } from '@material-ui/core'
import { Spacer } from 'common/components/molecules/Spacer'
import React from 'react'
import { FundPerformanceScenarioBarChart } from './Chart'

export interface ChartComponentProps {
    showBarChart: boolean
    benchmarkData: any
    fundSwitchData: any
    barChartData: any
    missingLabelsLength: number
    fundName: string
    benchmarkName: string
}

export const Chart: React.FC<ChartComponentProps> = ({
    showBarChart,
    benchmarkData,
    fundSwitchData,
    barChartData,
    missingLabelsLength,
    fundName,
    benchmarkName,
}) => {
    if (!showBarChart) return <></>
    return (
        <>
            <Spacer />
            <>
                <TextDS marginBottom={'32px'} variant={'title-xl'}>
                    Past Fund Performance
                </TextDS>
                <TextDS mb={'32px'} fontWeight={'bold'}>
                    This chart shows the fund's performance as the percentage
                    loss or gain per year over the last{' '}
                    {barChartData.length - missingLabelsLength} years
                </TextDS>
                {!benchmarkData?.FundExternalBenchmarkData?.[0] &&
                    benchmarkData?.FundBenchmarkData?.[0] && (
                        <TextDS mb={'32px'} fontWeight={'bold'}>
                            It can help you to assess how the fund has been
                            managed in the past and compare it to its benchmark.
                        </TextDS>
                    )}
                <TextDS mb={'32px'}>
                    <strong>Source:</strong> Irish Life Investment Managers
                </TextDS>
            </>
            <Box>
                <Stack
                    w={['full', '75%']}
                    height={['550px', '665px']}
                    spacing={8}
                >
                    <FundPerformanceScenarioBarChart
                        data={barChartData}
                        fundSwitchData={fundSwitchData}
                        fundName={fundName}
                        benchmarkName={benchmarkName}
                    />
                </Stack>
            </Box>
            {fundSwitchData && (
                <Box>
                    <TextDS variant='body-lg' color='monochrome.800'>
                        <svg
                            height={3}
                            width={30}
                            style={{ marginTop: '12px' }}
                        >
                            <line
                                x1='0'
                                y1='0'
                                x2='30'
                                y2='0'
                                strokeDasharray='6 6'
                                stroke='#EE870E'
                                strokeWidth={2.5}
                            />
                        </svg>
                        {`The benchmark for this fund changed in ${fundSwitchData.FundSwitchYear}. The benchmark performance in ${fundSwitchData.FundSwitchYear} is a combination of the previous benchmark and ${fundSwitchData.BenchmarkName} performance. Benchmark performance before ${fundSwitchData.FundSwitchYear} was achieved under circumstances that no longer apply.`}
                    </TextDS>
                </Box>
            )}
            {benchmarkData?.FundExternalBenchmarkData?.[0] && (
                <Box>
                    <TextDS>
                        For the external exchange traded Funds benchmark past
                        performance, please see{' '}
                        {
                            <Link
                                href={
                                    benchmarkData.FundExternalBenchmarkData[0][
                                        'LinkURL'
                                    ]
                                }
                            >
                                {
                                    benchmarkData.FundExternalBenchmarkData[0][
                                        'LinkText'
                                    ]
                                }
                            </Link>
                        }
                        .{' '}
                        {
                            benchmarkData.FundExternalBenchmarkData[0][
                                'SignpostText'
                            ]
                        }
                    </TextDS>
                </Box>
            )}
        </>
    )
}
