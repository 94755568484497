import React from 'react'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { Box, HStack } from '@chakra-ui/react'

const notFundName = ['compare', 'timestamp', 'year']

type CustomTooltipProps = {
    colorMap?: Record<string, string>
    payload?:
        | {
              payload: {
                  fundName?: string
                  value?: number
                  timestamp?: string
                  year?: string
                  compare?: boolean
                  benchmarkValue?: string
              }
              name?: string
          }[]
        | null
}

export function CustomTooltip(props: CustomTooltipProps) {
    // payload can be typeof null
    const { payload, colorMap = {} } = props
    const { payload: dataPoint = {} } = payload?.[0] ?? {}
    const {
        fundName = 'Fund',
        value = '',
        timestamp = '',
        year = '',
        compare,
        benchmarkValue = '',
    } = dataPoint
    const benchmarkName = payload?.[1]?.name ?? ''
    const prefix = timestamp && '€'
    const postfix = !timestamp && '%'
    const date = timestamp ? new Date(timestamp).toDateString() : year

    const fundNameValueMap = React.useMemo(() => {
        if (!compare) return []
        return Object.entries(dataPoint).filter(
            ([key]) => !notFundName.includes(key)
        ) as [string, number][]
    }, [compare, dataPoint])

    const renderCompareFundsContent = React.useMemo(() => {
        if (!compare || fundNameValueMap.length === 0) return null
        return (
            <Box>
                {fundNameValueMap.map(([key, price]) => {
                    return (
                        <HStack key={key} spacing={2}>
                            <Box
                                boxSize={2}
                                rounded='full'
                                bg={colorMap[key]}
                            />
                            <HStack spacing={0} justifyContent='space-between'>
                                <Text variant='body-sm' fontWeight='semibold'>
                                    {key}:&nbsp;&nbsp;
                                </Text>
                                <Text variant='body-sm' fontWeight='semibold'>
                                    {prefix}
                                    {price}
                                </Text>
                            </HStack>
                        </HStack>
                    )
                })}
            </Box>
        )
    }, [compare, fundNameValueMap, prefix, colorMap])

    const renderSingleFundContent = React.useMemo(() => {
        return (
            <>
                <Text variant='body-sm' fontWeight='semibold'>
                    {fundName}:&nbsp;&nbsp;{prefix}
                    {value}
                    {postfix}
                </Text>
                {benchmarkValue && (
                    <Text variant='body-sm' fontWeight='semibold'>
                        {!benchmarkName ? 'Benchmark' : benchmarkName}
                        :&nbsp;&nbsp;{prefix}
                        {benchmarkValue}
                        {postfix}
                    </Text>
                )}
            </>
        )
    }, [fundName, value, prefix, postfix, benchmarkValue, benchmarkName])

    return (
        <Box
            id='chart-tooltip'
            bg='white'
            rounded='sm'
            p={1}
            borderWidth='1px'
            borderColor='#5162AC'
            shadow='lg'
        >
            <Text variant='body-sm' fontSize='xs'>
                {date}
            </Text>
            {renderCompareFundsContent ?? renderSingleFundContent}
        </Box>
    )
}
