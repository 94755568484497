import axios from 'axios'
import { API_URL } from 'core/config'
import { useQuery } from 'react-query'

export async function getFundPerformanceData(fundIds: string) {
    const { data } = await axios.post<FundData>(
        `${API_URL}/myonlineservices/FundApi/RetrieveFundPerformanceCharts?fundId=${fundIds}`
    )
    return data
}

export type FundData = {
    BarChart: {
        Data: string
        Labels: string
    }
    LineChart: {
        Data: string
    }
    LaunchDate: string
    BenchmarkData: {
        ExtensionData: {}
        FundBenchmarkData:
            | {
                  ExtensionData: {} | null
                  BenchmarkID: string
                  BenchmarkName: string
                  BenchmarkPercReturn: number
                  BenchmarkYear: string
                  Fund: string
                  FundSwitch: string | null
                  HoldingFundId: string
              }[]
            | null
        FundBenchmarkDisclaimerText: string
        FundObjectivesText: string
        FundExternalBenchmarkData: any[]
        FundName?: string
    }
}

export function useFundPerformanceQuery(fundId: string) {
    return useQuery<FundData, Error>(['fundPerformance', fundId], () =>
        getFundPerformanceData(fundId)
    )
}
