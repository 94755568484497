export const palette = {
    BLACK: '#3A3160',
    WHITE: '#FFFFFF',
    BRAND_50: '#F7F8FB',
    BRAND_100: '#F1F3FD',
    BRAND_200: '#EBEDF7',
    BRAND_300_SECONDARY: '#C1C7E1',
    BRAND_400: '#99A2CD',
    BRAND_500: '#717FB8',
    BRAND_600_PRIMARY: '#5261AC',
    BRAND_700: '#4D599E',
    BRAND_800: '#1D2F7C',
    BRAND_900: '#0C1E68',
    VIBRANT_50: '#EEF0FC',
    VIBRANT_100_SECONDARY: '#E3E5F9',
    VIBRANT_200: '#CFD4FA',
    VIBRANT_300: '#9CA5EB',
    VIBRANT_400: '#8692E6',
    VIBRANT_500_PRIMARY: '#717FE2',
    VIBRANT_600: '#6977DF',
    VIBRANT_700: '#5E6CDA',
    VIBRANT_800: '#5463D6',
    VIBRANT_900: '#424FCF',
    ACCENT_50: '#EAF9F6',
    ACCENT_100: '#CBEFE9',
    ACCENT_200: '#A8E4DA',
    ACCENT_300: '#85D9CB',
    ACCENT_400: '#6AD1C0',
    ACCENT_500_PRIMARY: '#50C9B5',
    ACCENT_600: '#49C3AE',
    ACCENT_700: '#40BCA5',
    ACCENT_800: '#37B59D',
    ACCENT_900: '#27A98D',
    NEUTRAL_50: '#F5F4F7',
    NEUTRAL_100: '#ECEBF0',
    NEUTRAL_200: '#D8D6DF',
    NEUTRAL_300: '#C4C2D0',
    NEUTRAL_400: '#B0ADBF',
    NEUTRAL_500: '#9D98B0',
    NEUTRAL_600: '#8983A0',
    NEUTRAL_700: '#766F90',
    NEUTRAL_800: '#615A80',
    NEUTRAL_900: '#4E4670'
}