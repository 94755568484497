import { RouteComponentProps } from '@reach/router'
import { PRIIPS } from 'common/components/organisms/FundCentre/PRIIPS'
import { getLayoutData } from 'common/utils/getLayoutData'
import { getJumbotronData } from 'common/components/organisms/Jumbotron'
import React from 'react'

export interface PriipsProps extends RouteComponentProps {
    pageContext: any
}

export const KidsPage: React.FC<PriipsProps> = ({ pageContext }) => {
    return <PRIIPS {...getPRIIPSData(pageContext)} />
}

export const getPRIIPSData = (data: any) => {
    return {
        jumbotron: getJumbotronData(data),
        layout: getLayoutData(data),
        alerts: {
            alerts: (data.alerts__alerts || []).map((alert: any) => alert.text)
        }
    }
}

export default KidsPage
