import { Box } from '@material-ui/core'
import { Wrapper } from 'common/components/molecules/Wrapper'
import { Layout } from 'common/components/organisms/Layout'
import { useDesktop } from 'common/hooks/useDesktop'
import { FundData } from 'common/hooks/useFundPerformanceQuery'
import { useKidsDocuments } from 'common/hooks/useKidsDocuments'
import React, { useState } from 'react'
import { Jumbotron } from '../Jumbotron'
import { Chart } from './components/Chart'
import { Search } from './components/Search'
import { Table } from './components/Table'
import { Warnings } from './components/Warnings'
import {
    FundDocument,
    FundSwitchData,
    getPerformanceData,
    PRIIPSProps,
} from './utils'

export const PRIIPS: React.FC<PRIIPSProps> = ({
    jumbotron,
    layout,
    alerts,
}) => {
    const desktop = useDesktop()
    const documents: FundDocument[] = useKidsDocuments()

    const initialFilter = {
        PremiumTypeDesc: '',
        PublicProductName: '',
        SellerName: '',
        PublicFundName: '',
    }
    // const initialFilter = {
    //     PremiumTypeDesc: 'Single Premium',
    //     PublicProductName: 'AIB Portfolio Advance',
    //     SellerName: 'AIB',
    //     PublicFundName: 'Indexed UK Equity Fund',
    // }
    // const initialFilter = {
    //     PremiumTypeDesc: 'Single Premium',
    //     PublicProductName: 'Irish Life Investment Bond (Fitzgerald L&P)',
    //     SellerName: 'Financial Broker or Advisor',
    //     PublicFundName: 'Indexed Small Cap Equity Fund',
    // }

    const initialBenchmarkData: FundData['BenchmarkData'] = {
        ExtensionData: {},
        FundBenchmarkData: [],
        FundBenchmarkDisclaimerText: '',
        FundExternalBenchmarkData: [],
        FundObjectivesText: '',
    }
    const [filter, setFilter] = useState(initialFilter)
    const [kidYear, setKidYear] = useState('')
    const [kidMonth, setKidMonth] = useState('')
    const [isDisabled, setIsDisabled] = useState(true)
    const [showBarChart, setShowBarChart] = useState(false)
    const [showTable, setShowTable] = useState(false)
    const [showErrorState, setShowErrorState] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [errorCode, setErrorCode] = useState<number>(0) // initial value
    const [barChartData, setBarChartData] = useState([])
    const [fundStartYear, setFundStartYear] = useState('')
    const [benchmarkData, setBenchmarkData] =
        useState<FundData['BenchmarkData']>(initialBenchmarkData)
    const [performanceData, setPerformanceData] = useState<{
        [key: string]: any
    }>({})
    const [missingLabelsLength, setMissingLabelsLength] = useState<number>(0)
    const [fundSwitchData, setFundSwitchData] = useState<FundSwitchData>()
    const [fundName, setFundName] = useState('fundName')
    const [benchmarkName, setBenchmarkName] = useState('')

    const clearFilter = () => {
        setFilter(initialFilter)
        setShowErrorState(false)
        setShowTable(false)
        setShowBarChart(false)
    }
    return (
        <Layout {...layout}>
            <Jumbotron {...jumbotron} />
            <Wrapper style={{ backgroundColor: 'white' }}>
                <Box m={desktop ? 0 : 6} component='section'>
                    <Search
                        clearFilter={clearFilter}
                        documents={documents}
                        filter={filter}
                        setFilter={setFilter}
                        setKidYear={setKidYear}
                        setKidMonth={setKidMonth}
                        setIsDisabled={setIsDisabled}
                        kidMonth={kidMonth}
                        kidYear={kidYear}
                        isDisabled={isDisabled}
                        getPerformanceData={() => {
                            const props = {
                                setBarChartData,
                                setShowBarChart,
                                setShowTable,
                                setShowErrorState,
                                setPerformanceData,
                                setErrorCode,
                                setErrorMessage,
                                filter,
                                kidMonth,
                                kidYear,
                                documents,
                                setBenchmarkData,
                                setFundStartYear,
                                fundStartYear,
                                setMissingLabelsLength,
                                setFundSwitchData,
                                setBenchmarkName,
                                setFundName,
                            }
                            getPerformanceData(props)
                        }}
                    />
                    <Table
                        benchmarkData={benchmarkData}
                        performanceData={performanceData}
                        showTable={showTable}
                        showErrorState={showErrorState}
                        errorMessage={errorMessage}
                        filter={filter}
                        errorCode={errorCode}
                    />
                    <Chart
                        barChartData={barChartData}
                        benchmarkData={benchmarkData}
                        showBarChart={showBarChart}
                        fundSwitchData={fundSwitchData}
                        missingLabelsLength={missingLabelsLength}
                        fundName={fundName}
                        benchmarkName={benchmarkName}
                    />
                    <Warnings
                        alerts={alerts}
                        benchmarkData={benchmarkData}
                        fundStartYear={fundStartYear}
                        showBarChart={showBarChart}
                    />
                </Box>
            </Wrapper>
        </Layout>
    )
}
