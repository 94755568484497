import { JumbotronProps } from 'common/components/organisms/Jumbotron'
import { LayoutProps } from 'common/components/organisms/Layout'
import {
    FundData,
    getFundPerformanceData,
} from 'common/hooks/useFundPerformanceQuery'
import { getPerformanceScenarios } from 'common/hooks/useKidsDocuments'
import _ from 'lodash'
import { AlertsProps } from '../Alerts'

export interface FundSwitchData {
    BenchmarkName: string
    FundSwitchYear: string
}

export interface PRIIPSProps {
    jumbotron: JumbotronProps
    layout: LayoutProps
    alerts: AlertsProps
}
export interface FundDocument {
    Fund: string
    PremiumType: string
    PremiumTypeDesc: string
    ProductCode: string
    PublicFundName: string
    PublicProductName: string
    SellerCode: string
    SellerName: string
    KidYearsAndMonth?: []
}
export type DocumentFilter = Pick<
    FundDocument,
    'PremiumTypeDesc' | 'PublicProductName' | 'SellerName' | 'PublicFundName'
>
export interface DocumentOptions {
    PremiumTypeDesc: string[]
    PublicProductName: string[]
    SellerName: string[]
    PublicFundName: string[]
}

export const Months = new Map([
    [1, 'January'],
    [2, 'February'],
    [3, 'March'],
    [4, 'April'],
    [5, 'May'],
    [6, 'June'],
    [7, 'July'],
    [8, 'August'],
    [9, 'September'],
    [10, 'October'],
    [11, 'November'],
    [12, 'December'],
])

export const findMonth = (searchValue: string, map: any) => {
    for (let [key, value] of map.entries()) {
        if (value === searchValue) {
            return key
        }
    }
}

export const parseFinancialValue = (number: number) => {
    return new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'EUR',
        maximumSignificantDigits: 6,
    }).format(number)
}

export const getBenchmarkValue = (fundData: FundData, year: string) => {
    const result = fundData?.BenchmarkData?.FundBenchmarkData?.find(
        (benchmark) => benchmark.BenchmarkYear === year
    )
    return result?.BenchmarkPercReturn
}

export const getBenchmarkName = (fundData: FundData) => {
    if (fundData.BenchmarkData?.FundBenchmarkData?.length) {
        const name =
            fundData.BenchmarkData?.FundBenchmarkData?.find(
                (benchmark) => benchmark.FundSwitch === 'Yes'
            )?.BenchmarkName ??
            fundData.BenchmarkData?.FundBenchmarkData?.[0].BenchmarkName
        return name
    }
}

export const getFundSwitchData = (
    benchmarkData: FundData['BenchmarkData']['FundBenchmarkData']
) => {
    const fundSwitchData = benchmarkData?.find(
        (benchmark) => benchmark.FundSwitch === 'Yes'
    )
    if (!fundSwitchData) {
        return undefined
    }
    return {
        BenchmarkName: fundSwitchData.BenchmarkName,
        FundSwitchYear: fundSwitchData.BenchmarkYear,
    } as FundSwitchData
}

export const handleError = (
    errorNum: number,
    setErrorCode: (code: number) => void,
    setErrorMessage: (message: string) => void,
    setShowBarChart: (show: boolean) => void,
    setShowTable: (show: boolean) => void,
    setShowErrorState: (show: boolean) => void
) => {
    switch (errorNum) {
        case 1:
            setErrorCode(1)
            setErrorMessage(
                'There is insufficient data to provide a useful indication of past performance'
            )
            setShowBarChart(false)
            break
        case 2:
            setErrorCode(2)
            setErrorMessage('Performance scenarios are currently unavailable')
            setShowTable(false)
            break
        default:
            setErrorMessage('Performance scenarios are currently unavailable')
            break
    }

    setShowErrorState(true)
}

export const getResults = (filter: DocumentFilter, documents: FundDocument[]) =>
    _.filter(documents, filter)

export const getSingleResult = (collection: any, filter: DocumentFilter) =>
    _.filter(collection, (item) => {
        return item.KidYearsAndMonths?.length > 0
    })[0]

export const getKidMonth = (
    filter: DocumentFilter,
    year: string,
    documents: FundDocument[]
) => {
    const results: any = getResults(filter, documents)
    const result = getSingleResult(results, filter)

    if (
        !_.isNull(result) &&
        !_.isUndefined(result) &&
        !_.isNaN(result) &&
        result.hasOwnProperty('KidYearsAndMonths')
    ) {
        let months = result?.KidYearsAndMonths.map((element: any) => {
            if (element.KidYear === parseInt(year)) {
                return element.KidMonths
            }
        })
        // Add months less than 12 condition

        return months.flat()
    }
    return ['No data']
}

export const getKidYear = (
    filter: DocumentFilter,
    documents: FundDocument[]
) => {
    const results: any = getResults(filter, documents)
    const result = getSingleResult(results, filter)

    if (
        !_.isNull(result) &&
        !_.isUndefined(result) &&
        result.hasOwnProperty('KidYearsAndMonths')
    ) {
        let years = result?.KidYearsAndMonths.map((element: any) => {
            return element.KidYear.toString()
        })
        return Object.values(years)
    }
    return ['No data']
}

export const getPerformanceData = (props: {
    setBarChartData: any
    setShowBarChart: any
    setShowTable: any
    setShowErrorState: any
    setPerformanceData: any
    setErrorCode: any
    setErrorMessage: any
    filter: DocumentFilter
    kidMonth: string
    kidYear: string
    documents: FundDocument[]
    setBenchmarkData: any
    setFundStartYear: any
    fundStartYear: string
    setMissingLabelsLength: any
    setFundSwitchData: any
    setBenchmarkName: any
    setFundName: any
}) => {
    const {
        setBarChartData,
        setShowBarChart,
        setShowTable,
        setShowErrorState,
        setPerformanceData,
        setErrorCode,
        setErrorMessage,
        filter,
        kidMonth,
        kidYear,
        documents,
        setBenchmarkData,
        setFundStartYear,
        fundStartYear,
        setMissingLabelsLength,
        setFundSwitchData,
        setBenchmarkName,
        setFundName,
    } = props
    setBarChartData([])
    setShowErrorState(false)
    const results: any = getResults(filter, documents)
    const result = getSingleResult(results, filter)
    let numericMonth = findMonth(kidMonth, Months)

    getPerformanceScenarios(result, kidYear, numericMonth)
        .then((data) => {
            if (typeof data === 'string') {
                throw new Error(data)
            } else {
                setPerformanceData(data)
                setShowTable(true)
            }
        })
        .catch((error) => {
            handleError(
                2,
                setErrorCode,
                setErrorMessage,
                setShowBarChart,
                setShowTable,
                setShowErrorState
            )
        })

    getFundPerformanceData(result.Fund)
        .then((data) => {
            const props = {
                data,
                setBenchmarkData,
                setFundStartYear,
                fundStartYear,
                setErrorCode,
                setErrorMessage,
                setShowBarChart,
                setShowTable,
                setShowErrorState,
                findMissingLabels,
                setBarChartData,
                setMissingLabelsLength,
                setFundSwitchData,
                setBenchmarkName,
                setFundName,
            }
            processData(props)
        })
        .catch((error) => {
            handleError(
                2,
                setErrorCode,
                setErrorMessage,
                setShowBarChart,
                setShowTable,
                setShowErrorState
            )
        })
}

const processData = (props: {
    data: FundData
    setBenchmarkData: any
    setFundStartYear: any
    fundStartYear: string
    setErrorCode: any
    setErrorMessage: any
    setShowBarChart: any
    setShowTable: any
    setShowErrorState: any
    findMissingLabels: any
    setBarChartData: any
    setMissingLabelsLength: any
    setFundSwitchData: any
    setBenchmarkName: any
    setFundName: any
}) => {
    const {
        data,
        setBenchmarkData,
        setFundStartYear,
        fundStartYear,
        setErrorCode,
        setErrorMessage,
        setShowBarChart,
        setShowTable,
        setShowErrorState,
        findMissingLabels,
        setBarChartData,
        setMissingLabelsLength,
        setFundSwitchData,
        setBenchmarkName,
        setFundName,
    } = props
    setBenchmarkData(data.BenchmarkData)
    setFundStartYear(data.LaunchDate)
    const hasBenchmarkData = data?.BenchmarkData?.FundBenchmarkData?.length

    const yearOfLaunch = data.LaunchDate.split('/')[2]
    const { Data, Labels } = data.BarChart
    const fundPrices = Data.split(',')
    const labels = Labels.split(',')
    const currentYear = new Date().getFullYear()

    // If the fund started last year it won't have a full years data so raise an errror
    if (
        fundStartYear === (currentYear - 1).toString() ||
        fundPrices.length <= 2
    ) {
        handleError(
            1,
            setErrorCode,
            setErrorMessage,
            setShowBarChart,
            setShowTable,
            setShowErrorState
        )
        return
    }
    // Fund data for the year of launch would be partial, so removing it from the bar chart
    if (labels.includes(yearOfLaunch)) {
        const index = labels.indexOf(yearOfLaunch)
        labels.splice(index, 1)
        fundPrices.splice(index, 1)
    }
    if (labels.includes(currentYear.toString())) {
        fundPrices.pop()
        labels.pop()
    }
    // if data is more than 5 or more years and less than 10 years, appending 0 values to missing years to show last 10 years bar chart
    if (labels.length > 4 && labels.length <= 10) {
        const missingLabels = findMissingLabels(
            labels,
            10,
            setMissingLabelsLength
        )
        labels.unshift(...missingLabels)
        missingLabels.forEach(() => {
            fundPrices.unshift('0')
        })

        // if data is less than 5, appending 0 values to missing years to show last 5 years bar chart
    } else if (labels.length < 5) {
        const missingLabels = findMissingLabels(
            labels,
            5,
            setMissingLabelsLength
        )
        labels.unshift(...missingLabels)
        missingLabels.forEach(() => {
            fundPrices.unshift('0')
        })
    }
    const processedData = labels.reduce(
        (acc: any, label: string, index: number) => {
            // Fix at one deimal place
            let newVal = fundPrices[index]
            newVal = parseFloat(newVal).toFixed(1)

            acc.push({
                value: +newVal,
                benchmarkValue:
                    hasBenchmarkData && newVal !== '0.0'
                        ? getBenchmarkValue(data, label)
                        : undefined,
                year: +label,
            })
            return acc
        },
        []
    )
    setFundSwitchData(getFundSwitchData(data.BenchmarkData.FundBenchmarkData))
    setBarChartData(processedData)
    setShowBarChart(true)
    setBenchmarkName(getBenchmarkName(data))
    setFundName(data?.BenchmarkData?.FundName ?? 'Fund')
}

const findMissingLabels = (
    labels: any,
    requiredLength: number,
    setMissingLabelsLength: any
) => {
    const missingLength = requiredLength - labels.length
    setMissingLabelsLength(missingLength)
    const missingElementsArray = []
    for (let i = missingLength; i > 0; i--) {
        missingElementsArray.push((parseInt(labels[0]) - i).toString())
    }
    return missingElementsArray
}
