import { Stack } from '@chakra-ui/react'
import { useDesktop } from 'common/hooks/useDesktop'
import _ from 'lodash'
import React from 'react'
import {
    Bar,
    BarChart,
    Cell,
    Label,
    LabelList,
    Legend,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'
import { CustomTooltip } from '../../../CustomTooltip'
import { FundSwitchData } from '../../utils'
import { CustomLabel } from '../CustomLabel'

export interface FundPerformanceScenarioBarChartProps {
    data: {
        year: string
        value: number
        benchmarkValue: number
    }[]
    fundSwitchData: FundSwitchData
    fundName: string
    benchmarkName: string
}
export function FundPerformanceScenarioBarChart(
    props: Readonly<FundPerformanceScenarioBarChartProps>
) {
    const { data, fundSwitchData, fundName, benchmarkName } = props
    const desktop = useDesktop()
    const isExtraPaddingForFundSwitch = data.length <= 5
    if (_.isNull(data) || _.isUndefined(data)) {
        throw new Error('No data available for bar chart')
    }
    const hasBenchmarkData = data.some((datum) => datum.benchmarkValue)
    const setPaddingForFundSwitch = () => {
        if (desktop) {
            if (isExtraPaddingForFundSwitch) {
                return '-60px'
            } else {
                return '-30px'
            }
        } else if (!desktop && isExtraPaddingForFundSwitch) {
            return '-25px'
        } else {
            return '-15px'
        }
    }
    return (
        <Stack w='full' h='full'>
            <ResponsiveContainer width='100%' height='100%'>
                <BarChart
                    id='past_performance_chart'
                    data={data}
                    margin={{
                        top: 0,
                        right: 0,
                        left: desktop ? 100 : -10,
                        bottom: 0,
                    }}
                >
                    {hasBenchmarkData && <Legend style={{ color: '#fff' }} />}
                    <XAxis
                        dataKey='year'
                        padding={{
                            left: 10,
                            right: 10,
                        }}
                        interval={0}
                        fontSize={desktop ? undefined : '12px'}
                    >
                        <Label
                            value='YEAR'
                            position='insideLeft'
                            offset={-30}
                            dy={-5}
                            fill='rgb(58,49,96)'
                            fontSize='12px'
                        />
                    </XAxis>
                    <YAxis
                        domain={['dataMin', 'dataMax']}
                        tickCount={15}
                        padding={{
                            top: 35,
                            bottom: 35,
                        }}
                        fontSize={desktop ? undefined : '12px'}
                    >
                        <Label
                            value='PERFORMANCE % '
                            dx={desktop ? -15 : -20}
                            offset={desktop ? -1 : 44}
                            position='insideTop'
                            fill='rgb(58,49,96)'
                            fontSize='12px'
                            angle={desktop ? 0 : -90}
                        />
                    </YAxis>
                    <Tooltip content={<CustomTooltip />} />
                    <ReferenceLine y={0} stroke='#000' />
                    {fundSwitchData?.FundSwitchYear && (
                        <ReferenceLine
                            x={fundSwitchData.FundSwitchYear}
                            stroke='#EE870E'
                            strokeWidth={2.5}
                            strokeDasharray='6 6'
                            style={{
                                transform: `translateX(${setPaddingForFundSwitch()})`,
                            }}
                        />
                    )}
                    <Bar dataKey='value' name={fundName} fill='#5162AC'>
                        {data.map((datum, entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={+datum.value > 0 ? '#5162AC' : '#9CA5EB'}
                                stroke={
                                    +datum.value > 0 ? '#5162AC' : '#9CA5EB'
                                }
                            />
                        ))}
                        <LabelList
                            dataKey='value'
                            position='top'
                            style={{ color: 'rgba(58, 49, 96, 1)' }}
                            content={
                                <CustomLabel
                                    hasBenchmarkData={hasBenchmarkData}
                                />
                            }
                        />
                    </Bar>
                    {hasBenchmarkData && (
                        <Bar
                            dataKey='benchmarkValue'
                            fill='#C1C7E1'
                            stroke='4D599E'
                            name={benchmarkName}
                        >
                            {data.map((datum, entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={
                                        +datum.value > 0 ? '#C1C7E1' : '#EEF0FC'
                                    }
                                    stroke={
                                        +datum.value > 0 ? '#4D599E' : '#9CA5EB'
                                    }
                                />
                            ))}
                            <LabelList
                                dataKey='benchmarkValue'
                                position='top'
                                style={{ color: 'rgba(58, 49, 96, 1)' }}
                                content={
                                    <CustomLabel
                                        hasBenchmarkData={hasBenchmarkData}
                                    />
                                }
                            />
                        </Bar>
                    )}
                </BarChart>
            </ResponsiveContainer>
        </Stack>
    )
}
